<template>
    <div class="about" id="quotaReviewList">
      <div class="resu">
        <el-button  type="primary" size="mini" @click="back" icon="el-icon-arrow-left">返回</el-button>
      </div>
       <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="中才汇总" name="first">
           <div style="text-align: left;margin-bottom: 20px;">
            <el-button type="primary" icon="el-icon-download" @click="exportTabless">导出</el-button>
          </div>
      <div>
        <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="hztableData" fit border style="width: 100%">
          <el-table-column type="index" width="50" label="" />
          <el-table-column prop="summaryNo" :show-overflow-tooltip="true"  label="汇总表编号" />
          <el-table-column prop="recordNumber" :show-overflow-tooltip="true"  label="工资人数" />
          <el-table-column prop="totalNetSalary" :show-overflow-tooltip="true" label="总实发工资" ><template slot-scope="scope">{{accDiv(scope.row.totalNetSalary,100)}}</template></el-table-column>
          <el-table-column prop="createTime" width="200px" :show-overflow-tooltip="true" label="上传时间" />
          
        </el-table>
      </div>
      <!-- <div class="hzdate">
        <div>
          本月付款总额为：
        </div>
        <div>
          甲方签名/盖章：
        </div>
        <div>
          乙方签名/盖章：
        </div>
      </div> -->
        </el-tab-pane>

        <el-tab-pane label="中才明细" name="second">
          <!-- <div style="text-align: left">
            <el-form :inline="true" :model="queryForms" class="demo-form-inline">
              <el-form-item label="员工名字：">
                <el-input v-model="queryForms.name" placeholder="请输入查询" :clearable="true"></el-input>
              </el-form-item>
              <el-form-item label="员工身份证号码：">
                <el-input v-model="queryForms.idno" placeholder="请输入查询" :clearable="true"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerys" icon="el-icon-search">查询</el-button>
                <el-button type="primary" @click="onResets" icon="el-icon-refresh-right">重置</el-button>
              </el-form-item>
              <el-form-item>
                <el-button class="resu" type="primary" @click="back" icon="el-icon-arrow-left">返回上一页</el-button>
              </el-form-item>
            </el-form>
          </div> -->
          <div style="text-align: left;margin-bottom: 20px;">
            <el-button type="primary" icon="el-icon-download" @click="exportTableSuccess">导出</el-button>
          </div>
          <!-- <div style="display: flex;justify-content: space-between;">
            <span>汇总表编号：<b>{{$route.query.summaryNo}}</b></span>
            <span>上传时间：<b>{{$route.query.updateTime}}</b></span>
            <span>总记录：<b>{{total}}条</b></span>
          </div> -->
          <div>
              <el-table
              :data="tableDetailData"
              :header-cell-style="tableHeader"
              :cell-style="tableCell"
              border
              fit
              style="width: 100%">
              <el-table-column  type="index"  label="序号" />
              <!-- <el-table-column :show-overflow-tooltip="true" prop="uid"  label="员工ID" />  -->
              <el-table-column prop="name" label="员工名字" />
              <el-table-column prop="idno" label="身份证号码" :show-overflow-tooltip="true" />
              <el-table-column label="本月实发工资" ><template slot-scope="scope">{{accDiv(scope.row.netSalary,100)}}</template></el-table-column> 
              <el-table-column prop="createTime" label="创建时间" />
              <el-table-column prop="remarks" label="备注" />
              <el-table-column label="操作" >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="openUploadFrom(scope.row)" v-if="$route.query.status!=6" style="color: #09BF88">修改</el-button>
              </template>
            </el-table-column>
            </el-table>
            <el-pagination
            
            id="page"
              background
              @size-change="sizeChanges"
              @current-change="currentChanges"
              @prev-click="currentChanges"
              @next-click="currentChanges"
              :page-sizes="[5, 10, 20]"
              :page-size="queryForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totals"
            >
            </el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane label="银行流水" name="third">
          <div style="text-align: left">
            <el-form :inline="true" :model="queryForm" class="demo-form-inline">
              <el-form-item label="员工名字：">
                <el-input v-model="queryForm.name" placeholder="请输入查询" :clearable="true"></el-input>
              </el-form-item>
              <el-form-item label="员工身份证号码：">
                <el-input v-model="queryForm.idno" placeholder="请输入查询" :clearable="true"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuery" icon="el-icon-search">查询</el-button>
                <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div style="text-align: left;margin-bottom: 20px;">
            <el-button type="primary" icon="el-icon-download" @click="exportTable">导出</el-button>
          </div>

          <div style="display: flex;justify-content: space-between;">
            <span>发薪汇总表编号: <b>{{$route.query.summNO}}</b></span>
            <span>创建时间：<b>{{$route.query.createTime}}</b></span>
            <span>总记录：<b>{{total}}条</b></span>
          </div>
          <div>
              <el-table
                :data="tableData"
                :header-cell-style="tableHeader"
                :cell-style="tableCell"
                border
                fit
                style="width: 100%">
                <el-table-column  type="index"  label="序号" />
                <el-table-column prop="createTime" label="交易时间" :show-overflow-tooltip="true" />
                <el-table-column prop="issued" label="代发种类" :show-overflow-tooltip="true" />
                <el-table-column prop="bankcard" label="收方账号" :show-overflow-tooltip="true" />
                <el-table-column prop="name"  label="收方户名" :show-overflow-tooltip="true"  /> 
                <el-table-column prop="afterTaxSalaryString" label="金额" />
                <el-table-column prop="currency" label="币种" :show-overflow-tooltip="true" />
                <el-table-column prop="trsdsp" label="摘要" />
                <!-- <el-table-column prop="bnkflg" label="开户行" /> -->
                <el-table-column prop="bnkflg" :show-overflow-tooltip="true"  label="开户行">
                  <template slot-scope="scope">
                    <span v-if="scope.row.bnkflg == 'Y'">招行</span>
                    <span v-if="scope.row.bnkflg == 'N'">他行</span>
                  </template>
                </el-table-column>
                <el-table-column prop="stscod" label="记录状态">
                  <template slot-scope="scope">
                    <div v-for="(item, index) in itemList" v-bind:key="index" >
                      <div v-if="item.datavalue == scope.row.stscod">
                        {{  item.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="errdsp" label="错误描述" />
                
              </el-table>
              <el-pagination
                id="page"
                background
                @size-change="sizeChange"
                @current-change="currentChange"
                @prev-click="currentChange"
                @next-click="currentChange"
                :page-sizes="[5, 10, 20]"
                :page-size="queryForms.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </div>
        </el-tab-pane>

        <el-tab-pane label="发薪失败明细" name="Fail">
          <!-- <div style="text-align: left">
            <el-form :inline="true" :model="queryFailForm" class="demo-form-inline">
              <el-form-item label="员工名字：">
                <el-input v-model="queryFailForm.name" placeholder="请输入查询" :clearable="true"></el-input>
              </el-form-item>
              <el-form-item label="员工身份证号码：">
                <el-input v-model="queryFailForm.idno" placeholder="请输入查询" :clearable="true"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQueryss" icon="el-icon-search">查询</el-button>
                <el-button type="primary" @click="onResetss" icon="el-icon-refresh-right">重置</el-button>
              </el-form-item>
              <el-form-item>
                <el-button class="resu" type="primary" @click="back" icon="el-icon-arrow-left">返回上一页</el-button>
              </el-form-item>
            </el-form>
          </div> -->
          <div style="text-align: left;margin-bottom: 20px;">
            <el-button type="primary" icon="el-icon-download" @click="exportTableFail">导出</el-button>
          </div>
          
          <div>
              <el-table
              :data="tableFailDetailData"
              :header-cell-style="tableHeader"
              :cell-style="tableCell"
              border
              fit
              style="width: 100%">
              <el-table-column  type="index"  label="序号" />
              <!-- <el-table-column :show-overflow-tooltip="true" prop="uid"  label="员工ID" />  -->
              <el-table-column prop="name" label="员工名字" />
              <el-table-column prop="idno" label="身份证号码" :show-overflow-tooltip="true" />
              <el-table-column label="本月实发工资" ><template slot-scope="scope">{{accDiv(scope.row.netSalary,100)}}</template></el-table-column> 
              <el-table-column prop="createTime" label="创建时间" />
              <el-table-column prop="stscod" label="记录状态">
                  <template slot-scope="scope">
                    <div v-for="(item, index) in itemList" v-bind:key="index" >
                      <div v-if="item.datavalue == scope.row.stscod">
                        {{  item.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="errtxt" label="错误描述" />
              
            </el-table>
            <el-pagination
            
            id="page"
              background
              @size-change="sizeChangess"
              @current-change="currentChangess"
              @prev-click="currentChangess"
              @next-click="currentChangess"
              :page-sizes="[5, 10, 20]"
              :page-size="queryForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalss"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-dialog title="调整人员信息" :visible.sync="dialogFormVisible" width="50%"   style="text-align: initial;" >

        <el-form class="wageadjustment" :model="updateForm" :inline="true" :label-position="'right'"   ref="updateForm" label-width="150px">
           <el-form-item label="员工名字："  prop="hname" >
            <el-input  v-model="updateForm.hname" :precision="2" :controls='false' placeholder="请输入员工名字" />
          </el-form-item>
           <el-form-item label="身份证号码："  prop="hidno" >
            <el-input  v-model="updateForm.hidno" :precision="2" :controls='false' placeholder="请输入身份证号码"/>
          </el-form-item>
          <!-- <el-form-item label="备注：" prop="remark" >
            <el-input type="textarea" v-model="updateForm.remark"></el-input>
          </el-form-item> -->
        </el-form>

        
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false;">取 消</el-button>
          <el-button type="primary" @click="submitUpdate">确 定</el-button>
        </div>
      </el-dialog>
      
    </div>
</template>
  
<script>
import {accMul} from '../../util/calculateUtils';
  export default {
      data() {
        return {
          activeName: 'first',
          summaryStateList: null,
          tableData: [],
          tableDetailData:[],
          tableFailDetailData:[],
          hztableData:[],
          dialogFormVisible: false,
          queryForm: {
            salarySummarizingRecordsId: '',
            idno: '',
            name: '',
            pageNo: 1,
            pageSize: 10
          },
          queryForms: {
            id:'',
            idno: '',
            name: '',
            pageNo: 1,
            pageSize: 10
          },
          queryFailForm: {
            id:'',
            idno: '',
            name: '',
            pageNo: 1,
            pageSize: 10
          },
          queryFormid: {
            id:'',
            summNO:'',
            pageNo: 1,
            pageSize: 10
          },
          updateForm: {
          id:'',
          uid: '',
          qname:undefined,
          hname:undefined,
          qidno:undefined,
          hidno:undefined,
          remark: '',
          sessionId: '',
          

        },
          itemList:[],
          diction: {
              code: "salaryPayStatus",
          },
          total:0,
          totals:0,
          totalss:0,
        }
      },
      async created(){
        this.queryForm.salarySummarizingRecordsId = this.$route.query.salarySummarizingRecordsId;
        this.queryForms.id = this.$route.query.salarySummarizingId;
        this.queryFailForm.id = this.$route.query.salarySummarizingId;
        this.queryFormid.id = this.$route.query.salarySummarizingId;
        this.queryFormid.summNO = this.$route.query.summNO
        this.activeName = this.$route.query.activeName;
        await this.loadTables();  //汇总
        await this.loadTable();   //银行流水
        await this.loadSuccessDetailTable();  //成功
        await this.loadFailDetailTable();     //失败
        this.findDictionariesItemList(this.diction);
      },
      methods: {
        handleClick(tab, event) {
        console.log(tab, event);
      },
      back(){
        this.$router.back();
      },
      // 加载汇总表格
      async loadTables(){
        await this.api.salaryApi.findSalary(this.queryFormid).then(res =>{
          if(res.data != null && res.data != undefined){
            this.hztableData.push(res.data.data)
          }else{
            this.hztableData =  null;
            this.$message.error(res.msg);
          }
        })
      },
      // 加载银行流水表格
      async loadTable(){
        await this.api.salaryApi.payrollLogDetail(this.queryForm).then(res =>{
          res = res.data;
          if(res.code == 200){
            this.tableData = res.data.records;
            this.total=res.data.total;
          }else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
        })
      },
      // 加载成功明细表格
      async loadSuccessDetailTable(){
        // .bind(this);
        let flag = this;
        this.tableLoading = true;
        await this.api.salaryApi.summarizingSuccessDetail(this.queryForms).then(res =>{
          res = res.data;
          this.tableLoading = false;
          if(res.code == 200){
            this.tableDetailData = res.data.records;
            this.totals=res.data.total;
          }else{
            this.tableDetailData =  null;
            this.totals = 0;
            this.$message.error(res.msg);
          }
        })
      },
      // 加载失败明细表格
      async loadFailDetailTable(){
        // .bind(this);
        let flag = this;
        this.tableLoading = true;
        await this.api.salaryApi.summarizingFailDetail(this.queryFailForm).then(res =>{
          res = res.data;
          this.tableLoading = false;
          if(res.code == 200){
            this.tableFailDetailData = res.data.records;
            this.totalss=res.data.total;
          }else{
            this.tableFailDetailData =  null;
            this.totalss = 0;
            this.$message.error(res.msg);
          }
        })
      },
      async findDictionariesItemList(param) {
        let response = await this.api.platformApi.findDictionariesItemList(param);
        console.log("数据字典", response);
        this.itemList = response.data.data;
      },

      //修改
      openUploadFrom(row){
        // this.updateForm.oldSalaryAmount = row.payrollPayable
          this.updateForm.qname = row.name
        this.updateForm.qidno = row.idno
        this.updateForm.qsalary = row.payrollPayable
        this.updateForm.qtax = row.personaltax
        this.updateForm.oldSalaryAmount = row.netSalary
        this.updateForm.qincrementTaxation = row.incrementTaxation
        this.updateForm.qlocaltaxSurchargeTaxation = row.localtaxSurchargeTaxation
        this.updateForm.qservicecharge = row.servicecharge
        this.updateForm.qactualDeductibleExpenses = row.actualDeductibleExpenses
        this.updateForm.qinvoiceAmount = row.invoiceAmount
        this.updateForm.qtotalExpenses = row.totalExpenses
        this.updateForm.qdeductibleExpenses = row.deductibleExpenses

        this.updateForm.hname = row.name
        this.updateForm.hidno = row.idno
        this.updateForm.hsalary = row.payrollPayable/100
        this.updateForm.htax = row.personaltax/100
        this.updateForm.newSalaryAmount = row.netSalary/100
        this.updateForm.hincrementTaxation = row.incrementTaxation/100
        this.updateForm.hlocaltaxSurchargeTaxation = row.localtaxSurchargeTaxation/100
        this.updateForm.hservicecharge = row.servicecharge/100
        this.updateForm.hactualDeductibleExpenses = row.actualDeductibleExpenses/100
        this.updateForm.hinvoiceAmount = row.invoiceAmount/100
        this.updateForm.htotalExpenses = row.totalExpenses/100
        this.updateForm.hdeductibleExpenses = row.deductibleExpenses/100

        
        this.updateForm.uid =  row.uid;
        this.updateForm.id =  row.id;
        let flag = this;
        let pam  = {
          id : row.uid
        }
        this.api.salaryApi.salaryHistory(pam).then(res =>{
          res = res.data
          if(res.code==200){
            flag.logTableData = res.data.records;
          }
        })
        this.dialogFormVisible = true;
      },

      async submitUpdate(){
        await this.$refs['updateForm'].validate((valid) => {
          if (valid) {
            let flag = this;
            this.updateForm.sessionId = this.$getCookie("sessionId");
            flag.updateForm.hname = flag.updateForm.hname
            flag.updateForm.hidno = flag.updateForm.hidno
            flag.updateForm.hsalary =  accMul(flag.updateForm.hsalary,100);
            flag.updateForm.htax = accMul(flag.updateForm.htax,100);
            flag.updateForm.newSalaryAmount = accMul(flag.updateForm.newSalaryAmount,100);
            flag.updateForm.hincrementTaxation = accMul(flag.updateForm.hincrementTaxation,100);
            flag.updateForm.hlocaltaxSurchargeTaxation = accMul(flag.updateForm.hlocaltaxSurchargeTaxation,100);
            flag.updateForm.hservicecharge = accMul(flag.updateForm.hservicecharge,100);
            flag.updateForm.hactualDeductibleExpenses = accMul(flag.updateForm.hactualDeductibleExpenses,100);
            flag.updateForm.hinvoiceAmount = accMul(flag.updateForm.hinvoiceAmount,100);
            flag.updateForm.htotalExpenses = accMul(flag.updateForm.htotalExpenses,100);
            flag.updateForm.hdeductibleExpenses = accMul(flag.updateForm.hdeductibleExpenses,100);
            
            this.api.salaryApi.updateSalaryById(this.updateForm).then(res =>{
              res = res.data;
              if(res.code == 200){
                flag.loadSuccessDetailTable();
                flag.$message({
                  message: '修改成功！',
                  type: 'success'
                });
              }else{
                flag.$message.error(res.msg);
              }
            })
            this.dialogFormVisible = false;
          } else {
            return false;
          }
        });
      },


      async exportTable(){
        console.log("导出下载");
        console.log("----",this.$route.query.salarySummarizingRecordsId);
        await this.api.salaryApi.downloadSalaryLog({salarySummarizingLogId:this.$route.query.salarySummarizingRecordsId}).then(res =>{
          console.log(res)
          let fileNameEncode = "发薪记录.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
      async exportTableSuccess(){
        console.log("导出下载");
        await this.api.salaryApi.downloadSalarySuccess({salarySummarizingId:this.$route.query.salarySummarizingId}).then(res =>{
          console.log(res)
          let fileNameEncode = "中才明细.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
      async exportTableFail(){
        console.log("导出下载");
        await this.api.salaryApi.downloadSalaryFail({salarySummarizingId:this.$route.query.salarySummarizingId}).then(res =>{
          console.log(res)
          let fileNameEncode = "中才明细.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
      async exportTabless(){
        console.log("导出下载");
        await this.api.salaryApi.downloadTotalSalary({salarySummarizingLogId:this.$route.query.salarySummarizingId}).then(res =>{
          console.log(res)
          let fileNameEncode = "中才汇总.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
        // 条件查询
        onQuery() {
          this.loadTable();
        },
        // 重置
        onReset(){
          this.queryForm.idno='';
          this.queryForm.name='';
          this.loadTable();
        },
        // 条件查询
        onQuerys() {
          this.loadSuccessDetailTable();
        },
        onQueryss() {
          this.loadFailDetailTable();
        },
        // 重置
        onResets(){
          this.queryForms.idno='';
          this.queryForms.name='';
          this.loadSuccessDetailTable();
        },

        onResetss(){
          this.queryFailForm.idno='';
          this.queryFailForm.name='';
          this.loadFailDetailTable();
        },
        //current-change
        currentChange(page){
          this.queryForm.pageNo = page;
          this.loadTable();
        },
        currentChanges(page){
          this.queryForms.pageNo = page;
          this.loadSuccessDetailTable();
        },
        currentChangess(page){
          this.queryFailForm.pageNo = page;
          this.loadSuccessDetailTable();
        },
  
        // size-change
        sizeChange(size){
          this.queryForm.pageSize = size;
          this.loadTable();
        },
        sizeChanges(size){
          this.queryForms.pageSize = size;
          this.loadSuccessDetailTable();
        },
        sizeChangess(size){
          this.queryFailForm.pageSize = size;
          this.loadDetailTable();
        },
        tableHeader(column) {
        return "height:36px;background-color:#fafafa;color:#333333;padding:0";
        },
        tableCell(column) {
          return "height:40px;background-color:#fafafa;color:#000000;padding:0";
        },
        //除法函数
        accDiv(arg1, arg2){
           if(arg1!=null){
            var t1 = 0, t2 = 0, r1, r2;
            try {
              t1 = arg1.toString().split(".")[1].length;
            }
            catch (e) {
            }
            try {
              t2 = arg2.toString().split(".")[1].length;
            }
            catch (e) {
            }
            r1 = Number(arg1.toString().replace(".", ""));
            r2 = Number(arg2.toString().replace(".", ""));
            return (r1 / r2) * Math.pow(10, t2 - t1);
           }
           else{
            return null
           }
          }
      }
  }
</script>
  
<style scoped>
  .resu{
    text-align: right;
  }
  
  .hzdate{
    display: flex;
    margin: 180px 0px;
    justify-content: center;
    justify-content: space-evenly;
  }
  
  #page {
    float: right;
    margin-top: 15px;
  }
  
  #quotaReviewList {
    overflow: hidden;
    background-color: white;
    padding: 15px 20px 100px;
  }
  
  >>> .el-dialog__body {
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgb(228, 228, 228, 1);
    padding-left: 0;
    padding-right: 0;
  }
  
  >>> .el-drawer.rtl {
    overflow: scroll;
  }
  
  .wageadjustment{
    margin: 0 50px;
  }
  
  </style>
  
  